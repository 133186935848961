import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import English from '../translations/app.en.json';
import French from '../translations/app.fr.json';

const resources = {
  en: { app: English },
  fr: { app: French }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "fr",
    resources,
    ns: ['app'],
    defaultNS: 'app'
  });

export default i18n;