import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";

// Images
import polaroid from "./images/polaroid.png";

const App = () => {
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(
    localStorage.getItem("i18nextLng")
      ? localStorage.getItem("i18nextLng")
      : i18n.language
  );
  const [imageUrl, setImageUrl] = useState("");
  const queryParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );

  useEffect(() => {
    let currentUrl = window.location.pathname.replaceAll("/", "");
    let imgUrl = queryParams.get("url-image");

    if (imgUrl && imgUrl !== "") {
      switch (currentUrl) {
        case "en":
          i18n.changeLanguage(currentUrl);
          setLang(currentUrl);
          break;
        default:
          i18n.changeLanguage(lang);
          break;
      }
      setImageUrl(imgUrl);
      setLoading(false);
    }
  }, [queryParams, i18n, lang]);

  /**
   * Handles the language change
   * @param code - updated language
   * @global lang - current set language in state
   */
  let handleChange = (code) => {
    if (lang !== code) {
      let newUrl;
      if (lang === "fr") {
        newUrl = window.location.pathname.replace(
          "/",
          `/${code}/?url-image=${imageUrl}`
        );
      } else if (code === "fr") {
        newUrl = window.location.pathname.replace(
          `/${lang}/`,
          `/?url-image=${imageUrl}`
        );
      }

      window.location.replace(newUrl);
      localStorage.setItem("i18nextLng", code);
    }
  };

  /**
   * Handles the download of the blob storage image
   * @global imageUrl - image to download
   */
  let downloadImage = () => {
    saveAs(imageUrl, "mcq-image.jpg");
  };

  return (
    <>
      {!loading && (
        <div className="wrapper">
          {lang === "en" ? (
            <img
              src="https://bucketmonmcq.blob.core.windows.net/emarketing/prod/2022-05-25-ma-maison.png"
              alt="My place - your family portrait"
            />
          ) : (
            <img
              src="https://bucketmonmcq.blob.core.windows.net/emarketing/prod/2022-04-22-ma-maison.png"
              alt="Ma maison - Votre portrait de famille"
            />
          )}
          <br />
          <br />
          <div
            className="image"
            style={{
              backgroundImage: `url(${imageUrl})`,
              cursor: "pointer",
            }}
            onClick={() => downloadImage()}
          >
            <img src={polaroid} alt="" style={{ maxWidth: "100%" }} />
          </div>
          <div id="language-button">
            <button onClick={() => handleChange(t("language.lang"))}>
              {t("language.text")}
            </button>
          </div>
          <h1>{t("title")}</h1>
          <p dangerouslySetInnerHTML={{ __html: t("text.1") }} />
          <p dangerouslySetInnerHTML={{ __html: t("text.2") }} />
          {lang === "fr" && (
            <>
              <p dangerouslySetInnerHTML={{ __html: t("text.3") }} />
              <p className="rv">
                <a
                  href="https://www.mcq.org/fr/info-musee#_48_INSTANCE_D9DUriYZyKSC_=https://communication.mcq.org/EmailMarketing/NewsletterRegistration.aspx?language=fr&audience=Info-musées"
                  target="_blank"
                  rel="noreferrer"
                  className="btn"
                >
                  •&nbsp;{t("text.meetButton")}&nbsp;•
                </a>
              </p>
            </>
          )}
          <p dangerouslySetInnerHTML={{ __html: t("text.seeYouSoon") }} />
          <p dangerouslySetInnerHTML={{ __html: t("text.signature") }} />
          <p>
            <img
              src="https://bucketmonmcq.blob.core.windows.net/emarketing/prod/2022-05-12-goeland.png"
              width="150"
              alt="Capitaine Calliopée Goéland"
            />
          </p>
          <br />
          {lang === "en" ? (
            <img
              src="https://bucketmonmcq.blob.core.windows.net/emarketing/prod/2022-05-25-une-presentation.png"
              alt="Presented by"
              style={{ maxWidth: "100%" }}
            />
          ) : (
            <img
              src="https://bucketmonmcq.blob.core.windows.net/emarketing/prod/2022-04-22-une-presentation.png"
              alt="Une présentation"
              style={{ maxWidth: "100%" }}
            />
          )}
          <br />
          <img
            src="https://bucketmonmcq.blob.core.windows.net/emarketing/prod/2022-04-22-radio-canada.png"
            alt="Radio-Canada"
          />
          <br />
          <img
            src="https://bucketmonmcq.blob.core.windows.net/emarketing/prod/2022-04-22-via-capitale.png"
            alt="Via Capitale"
            className="logo-capitale"
          />
          <br />
          <br />
          <img
            src="https://bucketmonmcq.blob.core.windows.net/emarketing/prod/2022-04-22-musee-civilisation.png"
            alt="Musée de la civilisation"
          />
        </div>
      )}
    </>
  );
};

export default App;
